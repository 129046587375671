import styled from 'styled-components';
import Image from 'next/image';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Header from '../components/Header';
import HeroBannerComponent from '../components/HeroBannerComponent';
import Footer from '../components/Footer';
import { useRef } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import CtaButtonComponent from '../components/CtaButtonComponent';

const IntroSection = styled.div`
  position: relative;
  background-color: #ffffff;

  padding-top: 25px;
  padding-bottom: 45px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 0;
  }

  & .leftCol {
    @media (min-width: 992px) {
      padding-bottom: calc(500px + 45px);
    }
  }

  & .rightCol {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    padding: 0;
    min-height: 300px;
    @media (max-width: 991.98px) {
      margin-bottom: 45px;
      justify-content: flex-start;
    }
  }

  & .servingHandIllu {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }
`;

const FoodAlleyTeaserSection = styled.div`
  background-color: #f9f9f9;

  & p {
    line-height: 22px;
    font-size: 14px;
    letter-spacing: 0.62px;
    @media (min-width: 768px) {
      line-height: 28px;
      font-size: 15px;
      letter-spacing: 0.67px;
    }
  }

  & .w-800 {
    padding-top: 35px;
    padding-bottom: 35px;
    @media (min-width: 768px) {
      padding-top: 100px;
      padding-bottom: 100px;
      text-align: center;
    }
    position: relative;
    max-width: 800px;
  }

  & h2 {
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0.84px;
    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }
`;

const HowToSection = styled.div`
  background-color: #ffffff;

  padding-top: 35px;
  padding-bottom: 35px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  & p.margin-bottom {
    margin-bottom: 35px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }
`;

const BulletPointList = styled.div`
  & ul {
    list-style-type: none;
    padding-left: 0;
    counter-reset: orderedlist;
  }

  & ul li {
    display: inline-flex;
    align-items: center;
    font-weight: 400;

    font-size: 15px;
    letter-spacing: 0.62px;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 17px;
      letter-spacing: 0.75px;
      margin-bottom: 30px;
    }
  }

  & ul li::before {
    counter-increment: orderedlist;
    content: counter(orderedlist);
    display: flex;
    float: left;

    justify-content: center;
    align-items: center;
    background-color: var(--bs-primary);
    color: #ffffff;
    flex-shrink: 0;
    padding-left: 1px;

    height: 26px;
    width: 26px;
    border-radius: 13px;
    font-size: 15px;
    margin-right: 15px;
    @media (min-width: 768px) {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      font-size: 20px;
      margin-right: 20px;
    }
  }
  & ul li::after {
    clear: left;
  }
`;

const MvpRestaurantSection = styled.div`
  background-color: #f9f9f9;

  padding-top: 25px;
  padding-bottom: 45px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const StyledIconsLine = styled(IconsLine)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;

  transform: translateY(-50%);
  height: 20px;
  @media (min-width: 768px) {
    height: 30px;
    transform: translateY(-50%);
  }
`;

const ArticleBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 460px;
    flex-wrap: wrap;
    & > *:nth-child(even) {
      margin-top: -30px;
    }
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
  @media (min-width: 1400px) {
    width: 600px;
  }
`;

const ArticlePositioner = styled.div`
  display: block;
  position: relative;
  min-height: 300px;

  @media (max-width: 991.98px) {
    overflow-x: scroll;
    scroll-behavior: smooth;
    // hide scrollbars
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    white-space: nowrap;
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 30px;

    display: flex;
    justify-items: space-between;
  }
  @media (max-width: 575.98px) {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
`;

/** Variable stuff to change when creating a new landingpage */
import srcHeroImage from '../images/Landingpage-Pizza.jpg';
import srcServingHandIllu from '../public/svg/Handy-Pizza.svg';
import IconsLine from '../components/IconsLine';
import ArticleBoxComponent from '../components/ArticleBoxComponent';

import imgMargherita from '../images/lp-pizza/box-margherita.png';
import imgSalami from '../images/lp-pizza/box-salami.png';
import imgSchinken from '../images/lp-pizza/box-ham.png';
import imgVeggie from '../images/lp-pizza/box-vegetarisch.png';
import { PhoneSliderComponent } from '../components/PhoneSliderComponent';
import TopRestaurantsComponent from '../components/TopRestaurantsComponent';
import resolveBasePath from '../util/resolveBasePath';
import { FaqComponent } from '../components/FaqComponent';

const _landingPageTitle = 'Pizza';
/** END Edit Area */

export default function PizzaPage(props) {
  const zipRef = useRef();
  const { zip } = props;

  const seoTitle = `${_landingPageTitle} bestellen und liefern lassen - bei FoodAlley.de`;
  const seoDescription = `Jetzt schnell und einfach ${_landingPageTitle} bestellen und bequem nachhause liefern lassen - bei deinen Lieblingsrestaurants über FoodAlley.de - Die Alternative!`;
  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={seoTitle} />
        <meta property="twitter:description" content={seoDescription} />
      </Head>
      <Header />
      <HeroBannerComponent
        zipRef={zipRef}
        title={_landingPageTitle}
        imgSrc={srcHeroImage}
        filterName={_landingPageTitle}
      />
      <IntroSection>
        <Container>
          <Row>
            <Col xs={12} lg={6} className="leftCol pe-lg-5">
              <h3>{_landingPageTitle} bestellen bei dem Lieferservice in Deiner Nähe</h3>
              <h2>Deine Lieblingspizza bestellen</h2>
              <p>
                Du hast Lust auf eine frisch belegte, im Holzofen mit leckerem Käse überbackene
                Pizza? Dein Lieferservice in deiner Region liefert dir, was immer du dir aussuchst.
              </p>
              <p>
                Egal ob Pizza Salami, Pizza Hawaii, Pizza Funghi oder einer klassischen Pizza
                Margherita - bei <strong>FoodAlley</strong> in nur drei Schritten zu deinem Essen.
              </p>
              <p style={{ marginBottom: '40px' }}>
                Lass dir dein Essen bequem nach Hause oder zum Arbeitsplatz liefern und genieße
                einen gemütlichen Abend mit einer leckeren Pizza.
              </p>

              <CtaButtonComponent landingPageTitle={_landingPageTitle} zipRef={zipRef} />
            </Col>
            <Col xs={12} lg={6} className="rightCol">
              <ArticlePositioner>
                <ArticleBoxWrapper>
                  <ArticleBoxComponent
                    imgSrc={imgMargherita}
                    title="Pizza Margherita"
                    subtitle="Der traumhafte Klassiker"
                    link={resolveBasePath(
                      `stores${zip ? '/' + zip + '/' : ''}?filter=Pizza`,
                      props.env,
                    )}
                  />
                  <ArticleBoxComponent
                    imgSrc={imgSalami}
                    title="Pizza Salami"
                    subtitle="Aus bester Qualität"
                    link={resolveBasePath(
                      `stores${zip ? '/' + zip + '/' : ''}?filter=Pizza`,
                      props.env,
                    )}
                  />
                  <ArticleBoxComponent
                    imgSrc={imgSchinken}
                    title="Pizza Schinken"
                    subtitle="Hauchzart geschnitten"
                    link={resolveBasePath(
                      `stores${zip ? '/' + zip + '/' : ''}?filter=Pizza`,
                      props.env,
                    )}
                  />
                  <ArticleBoxComponent
                    imgSrc={imgVeggie}
                    title="Pizza Vegetarisch"
                    subtitle="Frisch aus dem Ofen"
                    link={resolveBasePath(
                      `stores${zip ? '/' + zip + '/' : ''}?filter=Pizza`,
                      props.env,
                    )}
                  />
                </ArticleBoxWrapper>
              </ArticlePositioner>
            </Col>
          </Row>
        </Container>
        <div className="servingHandIllu">
          <Image src={srcServingHandIllu} height={500} alt="Pizza liefern lassen" />
        </div>
      </IntroSection>

      <FoodAlleyTeaserSection>
        <Container className="w-800">
          <StyledIconsLine />
          <h2>Leckere Pizza bestellen in deiner Umgebung</h2>
          <p>
            FoodAlley ist eine echte Alternative zu den bisher am Markt bestehenden Lieferdiensten
            und Essenslieferanten. Bei jeder Bestellung hilfst du den Inhabern der Restaurants -
            dank unserem <em>fairen Kostenmodell</em> für Restaurantbetreiber.
          </p>
          <p>
            Bestelle jetzt bei deinem Lieblingsrestaurant und schicke deine Bestellung ab. Geliefert
            wird nach Hause, zur Mittagspause zur Arbeit oder auch ins Hotel. Zur Abholung kannst du
            auch jederzeit bestellen und dir die Wartezeit sparen. Egal ob Pizza, Burger, Pasta -
            bei FoodAlley findest Du alles auf was du Lust hast.
          </p>
          <CtaButtonComponent
            landingPageTitle={_landingPageTitle}
            zipRef={zipRef}
            style={{ marginTop: '15px' }}
          />
        </Container>
      </FoodAlleyTeaserSection>

      <HowToSection>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
              <PhoneSliderComponent />
            </Col>
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
              <h3>{_landingPageTitle} bestellen über FoodAlley.de</h3>
              <h2>Schnell und sicher zu deiner {_landingPageTitle}</h2>

              <p>
                Wenn man Hunger hat, kann es nicht schnell genug gehen. Daher haben wir bei
                FoodAlley den Bestellprozess so einfach und effektiv wie möglich gestaltet.
              </p>
              <p className="margin-bottom">
                Bei FoodAlley erwartet dich eine Vielzahl an Restaurants und Lieferdiensten für
                Pizzen mit jeder Menge Zutaten – ob herzhaft, vegetarisch oder vegan, für jeden ist
                etwas dabei!
              </p>

              <BulletPointList>
                <ul>
                  <li>Gib deine Postleitzahl ein</li>
                  <li>Wähle aus einem der Restaurants in deiner Umgebung</li>
                  <li>Finde dein Lieblingsessen und füge es dem Warenkorb hinzu</li>
                  <li>Schnell und sicher bestellen und bequem liefern lassen</li>
                </ul>
              </BulletPointList>
            </Col>
          </Row>
        </Container>
      </HowToSection>

      <MvpRestaurantSection>
        <Container>
          <Row>
            <Col xs={12} md={6} className="pe-lg-5">
              <h3>Beliebte Restaurants in der Umgebung bei FoodAlley.de</h3>
              <h2>Wähle dein Lieblingsrestaurant</h2>

              <p>
                Bestelle jetzt die <strong>Pizza deiner Wahl</strong> und lass es dir schmecken. Mit
                unserer Vielzahl an Partner-Restaurants sind wir auch in deiner Nähe und liefern dir
                deine Pizza schnell & einfach nachhause!
              </p>
              <p>
                Qualität steht hierbei an vorderster Stelle: Täglich sorgen unsere Partner mit
                frischen Zutaten für ein unvergessliches Erlebnis! Bestelle jetzt dein Essen und
                lass es dir liefern bei deinem {_landingPageTitle} Lieferservice in der Nähe.
              </p>
              <p style={{ marginBottom: '30px' }}>
                Selbstverständlich kannst du deine Pizza auch zum Abholen bestellen. Bei
                FoodAlley.de sind dir keine Grenzen gesetzt.
              </p>

              <CtaButtonComponent landingPageTitle={_landingPageTitle} zipRef={zipRef} />
            </Col>
            <Col xs={12} md={6}>
              <TopRestaurantsComponent categoryKeyword="Pizza" zip={props.zip} env={props.env} />
            </Col>
          </Row>
        </Container>
      </MvpRestaurantSection>

      <FaqComponent />
      <Footer />
    </>
  );
}

PizzaPage.propTypes = {
  zip: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
};
